import React from 'react'
import TeamCardItem from './TeamCardItem';
import './TeamCards.css';

function TeamCards() {
  return (
    <div className='team_cards'>
      <div className="team_cards__container">
          <div className="team_cards__wrapper">
              <ul className="team_cards__items">
                <TeamCardItem 
                  src="images/teamPhotos/Ilija.jpg"
                  title="Ilija Jankovic"
                  href="https://www.linkedin.com/in/ilija-jankovic/"
                  description="Managing Director/Software Engineer"/>
                  <TeamCardItem 
                  src="images/teamPhotos/Billy.png"
                  title="Billy Burton"
                  href="https://www.linkedin.com/in/william-burton-84b3b11aa/"
                  description="Director/Software Engineer"/>
              </ul>
              <ul className="cards__items">
                  <TeamCardItem 
                  src="images/teamPhotos/Kenan.jpg"
                  title="Kenan Grant"
                  href="https://www.linkedin.com/in/kenan-grant-7143171aa/"
                  description="Software Engineer"/>
                  <TeamCardItem 
                  src="images/teamPhotos/Yaksh.jpg"
                  title="Yaksh Kataria"
                  href="https://www.linkedin.com/in/yakshk/"
                  description="Software Engineer"/>
                  <TeamCardItem 
                  src="images/teamPhotos/Lewis.jpg"
                  title="Lewis Villavicencio"
                  href="https://www.linkedin.com/in/lewis-villavicencio/"
                  description="Software Engineer/ UX Designer"/>
              </ul>
              
          </div>
      </div>
    </div>
  )
}

export default TeamCards
