import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-scroll';

function HeroSection() {
  return (
    <div className='hero-container' id="home">
      <video autoPlay loop muted playsinline>
        <source src="/videos/video2.mp4" type="video/mp4"></source>
      </video>
      <h1>YOUR DREAM APP AWAITS</h1>
      <p>What are you waiting for?</p>
      <div className='hero-btns'>
        {/* <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          path='/team'
        >
          MORE ABOUT THE TEAM
        </Button> */}
        <Link className="btns btn btn-mobile btn--outline btn--large team-scroll" activeClass="active" smooth spy to="about">
          ABOUT THE TEAM
        </Link>
        <Link className="btns btn btn-mobile btn--primary btn--large" activeClass="active" smooth spy to="enquire">APPLY FOR APP</Link>
      </div>
    </div>
  );
}

export default HeroSection;