import React, {useState, useEffect } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import Footer from '../Footer';
import TeamCards from '../TeamCards'
import ContactUsComponent from '../ContactUsComponent';

function Home () {

    return (
        <>
        <HeroSection />
        
        <div className="statement-wrapper">
            <div className="statement" id="about">
                <h1 className="titles" > About Us </h1>
                <br></br>
                <p>
                Need a mobile app idea developed, but don’t know where to look?
                We’ve got you covered.
                Nezen is a custom software solutions company based in New Zealand which aims to provide high quality mobile app development services for your needs. Our primary goal is to work with you to understand exactly what your idea entails at every step of the process. We will keep you up to date with our decisions and development, creating a dynamic relationship in order to turn your app idea into a reality.
                Being a startup company, our prices are guaranteed to be competitive while not compromising on the quality of your product.
                Inquire now, or email us at development@nezen.co.nz
                </p>
            </div>
        </div>
        <div className="team-wrapper" id="team">
            <h1 className="team-title">Our Team</h1>
            <TeamCards />
        </div>
        <Cards />
        <ContactUsComponent />
        <Footer />
        </>
    )
}

export default Home;