import React from 'react'
import emailjs from 'emailjs-com'
import './Button.css';
import './ContactUsComponent.css'
import { useState } from 'react';

function ContactUsComponent() {

    const [status_text, status]  = useState('')

    function updateStatus () {
      status("Thank you for submitting! We will get back to you as soon as we can.")
    }

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_63jkv3o', 'template_hd7lfhu', e.target, 'WvKaN1MGr5q3uURmH')
          .then((result) => {
              updateStatus()
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };

      return (
        <div className="contactme" id="enquire">
          <div className="contactOverlay">
            <div className="container">
              <div className="form">
                <form action="" onSubmit={sendEmail}>
                  <div className="formWord">
                    <h2>Let us know about your app idea!</h2>
                    <span>Full Name</span>
                    <input className="input100" type="text" name="name" required placeholder="Albert Einstein" />
                    <span>Email</span>
                    <input className="input100" type="text" name="email" required placeholder="albert@gmail.com" />
                    <span>App Idea</span>
                    <textarea name="message" required placeholder="Your cool app idea!"></textarea>
                    <p className='status'>{status_text}</p>
                    <input className="btn--dark-outline btn--large"type="submit" value="Send" style={{cursor: 'pointer'}}/>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      );
}

export default ContactUsComponent
