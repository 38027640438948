import React from 'react'

function CardItem(props) {
  return (
    <>
        <li className="cards__item">
            <a href={props.href} className="cards__item__link">
                <figure className="cards__item__pic-wrap" data-category={props.label}>
                    <img src={props.src} className="cards__item__img" />
                </figure>
                <div className="cards__item__info">
                    <h5 className="cards__item__title">
                        {props.title}
                    </h5>
                    <p className="cards__item__description">{props.description}</p>
                </div>
            </a>
        </li>
    </>
  )
}

export default CardItem
