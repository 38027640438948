import React, {useState, useEffect} from 'react'
import {Link as LinkScroll} from 'react-scroll'
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton()
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
        <nav className="navbar">
            <div className="navbar-container">
                <LinkScroll className='nezen-logo' activeClass="active" smooth spy to="home" offset={-100} onClick={closeMobileMenu}>
                  <img src='/images/nezenLogoHorizontal.png' width="125px" height="auto"/> 
                </LinkScroll>
                <div className = 'menu-icon' onClick={handleClick}>
                  <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                  <li className='nav-item'>
                    <LinkScroll className='nav-links' activeClass="active" smooth spy to="home" offset={-100} onClick={closeMobileMenu}>
                      Home
                    </LinkScroll>
                  </li>
                  <li className='nav-item'>
                    <LinkScroll className='nav-links' activeClass="active" smooth spy to="team" offset={-80} onClick={closeMobileMenu}>
                      Team
                    </LinkScroll>
                  </li>
                  <li className='nav-item'>
                    <LinkScroll className='nav-links' activeClass="active" smooth spy to="portfolio" offset={-100} onClick={closeMobileMenu}>
                      Portfolio
                    </LinkScroll>
                  </li>
                  <li className='nav-item'>
                    <LinkScroll className='nav-links-mobile' activeClass="active" smooth spy to="enquire" offset={-100} onClick={closeMobileMenu}>
                      Apply for App
                    </LinkScroll>
                  </li>
                </ul>
                {/* {button && <Button buttonStyle='btn--dark-outline' path="/contact-us">ENQUIRE</Button>} */}
                {button && <LinkScroll className="btn btn-mobile btn--dark-outline btn--small" activeClass="active" smooth spy to ="enquire">INQUIRE</LinkScroll>}
            </div>
        </nav>
    </>
  )
}

export default Navbar
