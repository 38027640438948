import React from 'react'
import {Link as LinkScroll} from 'react-scroll'
import { Button } from './Button'
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <section className="social-media">
          <div className="social-media-wrap">
              <div className="footer-logo">
                <LinkScroll className='nezen-logo' activeClass="active" smooth spy to="home" offset={-100} >
                  <img src='/images/nezenHorizontalWhite.png' width="125px" height="auto"/> 
                </LinkScroll>
              </div>
              <small className="website-rights">NEZEN © 2022</small>
              <div className="social-icons">
                  <a href="https://www.facebook.com/nezenltd" target="_blank" className="social-icon-link facebook" aria-label="Facebook">
                        <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/nezenltd/about/" className="social-icon-link linkedin" target="_blank" aria-label="LinkedIn">
                        <i className="fab fa-linkedin"></i>
                  </a>
                  <a href="https://www.instagram.com/nezenltd/" className="social-icon-link linkedin" target="_blank" aria-label="LinkedIn">
                        <i className="fab fa-instagram"></i>
                  </a>
              </div>
          </div>
      </section>
    </div>
  )
}

export default Footer
