import React from 'react'
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='cards' id="portfolio">
      <h1 className="previous-work-title"> Our Previous Work </h1>
      <div className="cards__container">
          <div className="cards__wrapper">
              <ul className="cards__items">
                  <CardItem 
                  src="images/airBeauty.png"
                  title="Air Beauty"
                  label="App"
                  href="https://apps.apple.com/gb/app/air-beauty/id1600830509"
                  description="Business listings app for local businesses situated in Australia."/>
                  <CardItem 
                  src="images/localNZ.png"
                  title="The Local NZ"
                  label="App"
                  href="https://apps.apple.com/us/app/the-local-nz/id1558958320"
                  description="Business listings app for local businesses situated in New Zealand."/>
              </ul>
          </div>
      </div>
    </div>
  )
}

export default Cards
